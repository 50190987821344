import type { ThumborQuality } from '@canalplus/dive';
import { THUMBOR } from '@canalplus/dive-utils';
import type { I18nTranslationFunction } from '@canalplus/mycanal-i18n';
import { SchemaArticle } from '@canalplus/mycanal-sharedcomponent';
import type { IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import type { OfferLocation, OfferZone } from '@canalplus/sdk-core';
import { FragmentContentType } from '@canalplus/sdk-hodor';
import type { ApiV2ArticleInformation } from '@dce-front/hodor-types/api/v2/article/definitions';
import classNames from 'classnames/bind';
import { memo, type JSX, type ReactNode } from 'react';
import { ArticleContent } from './ArticleContent/ArticleContent';
import { Cover } from './Cover/Cover';
import styles from './CreativeMedia.module.css';
import { RelatedArticleFragment } from './Fragments/RelatedArticleFragment/RelatedArticleFragment';
import { LangProvider } from './lang';
import type { RelatedArticleLinkerProps } from './types';

const cx = classNames.bind(styles);

export type CreativeMediaProps = {
  appSessionId: string;
  featCreativeMediaRC: boolean;
  isAuthenticated: boolean;
  isImmersive: boolean;
  langKey: string;
  Linker: (props: any) => ReactNode;
  microEligibility: string;
  offerLocation: OfferLocation;
  offerZone: OfferZone;
  passToken: string;
  renderLinker: (props: RelatedArticleLinkerProps) => JSX.Element;
  sharingURL: string;
  t: I18nTranslationFunction<any>;
  tokenCMS: string;
  trackingKeyId: string;
  apiV2ArticleInformation?: ApiV2ArticleInformation;
  deviceId?: string;
  passId?: string;
  profileId?: number;
  qualityUserSettings?: ThumborQuality;
};

function CreativeMediaBase({
  appSessionId,
  apiV2ArticleInformation,
  deviceId,
  featCreativeMediaRC,
  isAuthenticated,
  isImmersive,
  langKey,
  Linker,
  microEligibility,
  offerLocation,
  offerZone,
  passId,
  passToken,
  profileId,
  qualityUserSettings = THUMBOR.QUALITY_DEFAULT,
  renderLinker,
  sharingURL = '',
  t,
  tokenCMS,
  trackingKeyId,
}: CreativeMediaProps) {
  const playerCredentials: IAPICredentialsRaw = {
    appSessionId,
    deviceId,
    microEligibility,
    passToken,
    profileId: profileId !== undefined ? profileId.toString() : undefined,
    passId,
    tokenCMS,
    trackingKeyId,
  };

  if (
    !apiV2ArticleInformation ||
    (apiV2ArticleInformation &&
      Object.keys(apiV2ArticleInformation).length === 0)
  ) {
    return null;
  }

  const { fragments } = apiV2ArticleInformation;

  if (!fragments?.[0]) {
    return null;
  }

  const { content, type: typeString } = fragments[0];
  const type: FragmentContentType = typeString as FragmentContentType;

  const isCoverActive =
    content !== undefined &&
    (type === FragmentContentType.MainImage ||
      type === FragmentContentType.Video);
  const isCoverImage = type === FragmentContentType.MainImage;

  const relatedArticleFragmentData = fragments.find(
    (fragment) => fragment.type === FragmentContentType.RelatedArticles
  )?.content;

  return (
    <LangProvider value={{ t }}>
      <div
        className={cx('creativeMedia', {
          [styles.creativeMedia__wrapper!]: !isCoverImage && !isImmersive,
          [styles.creativeMedia__immersiveWrapper!]:
            !isCoverImage && isImmersive,
        })}
      >
        {!isAuthenticated && (
          <SchemaArticle informations={apiV2ArticleInformation} />
        )}
        {isCoverActive && (
          <div
            className={cx('creativeMedia__cover', {
              [styles['creativeMedia__cover--image']!]:
                isCoverImage && !isImmersive,
            })}
          >
            <Cover
              type={type}
              content={content}
              offerLocation={offerLocation}
              offerZone={offerZone}
              qualityUserSettings={qualityUserSettings}
              isImmersive={isImmersive}
              playerCredentials={playerCredentials}
            />
          </div>
        )}
        <div
          className={cx('creativeMedia__content', {
            [styles['creativeMedia__content--coverImage']!]: isCoverImage,
            [styles['creativeMedia__content--coverImage--immersive']!]:
              isImmersive && isCoverImage,
          })}
        >
          <ArticleContent
            fragments={fragments}
            playerCredentials={playerCredentials}
            langKey={langKey}
            offerLocation={offerLocation}
            offerZone={offerZone}
            qualityUserSettings={qualityUserSettings}
            sharingURL={sharingURL}
            articleTitle={apiV2ArticleInformation.title ?? ''}
          />
          {relatedArticleFragmentData?.articles && featCreativeMediaRC && (
            <>
              <div className={cx('creativeMedia__divider')}>
                <hr className={cx('creativeMedia__divider__line')} />
              </div>
              <RelatedArticleFragment
                articles={relatedArticleFragmentData?.articles}
                isImmersive={isImmersive}
                Linker={Linker}
                qualityUserSettings={qualityUserSettings}
                renderLinker={renderLinker}
              />
            </>
          )}
        </div>
      </div>
    </LangProvider>
  );
}

export const CreativeMedia = memo(CreativeMediaBase);
